// src/components/Header.js
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "../features/theme/themeSlice";
import ThemeToggle from "./ThemeToggle";
import Hamburger from "./Hamburger";
import { logout } from "../features/auth/authSlice";
import { deselectBridge } from "../features/bridge/bridgeSlice";
import { setView } from "../features/view/viewSlice";
import { closeMenu } from "../features/menu/menuSlice";

const Header = () => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme);
  const isLoggedIn = useSelector((state) => state.auth.user);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleHomeClick = () => {
    dispatch(deselectBridge());
    dispatch(setView("home"));
    dispatch(closeMenu());
  };

  return (
    <header className="header">
      <div className="left-container">
      <div className="top-logo" onClick={handleHomeClick} />
        {isLoggedIn && (
          <div className="logout">
            <button onClick={handleLogout}>
              <span className="logout-text">Logout</span>
            </button>
          </div>
        )}
      </div>
      <div className="right-container">
        <ThemeToggle
          theme={theme}
          toggleTheme={() => dispatch(toggleTheme())}
        />
        <Hamburger />
      </div>
    </header>
  );
};

export default Header;
