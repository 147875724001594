// src/components/Footer.js
import React from "react";

const Footer = () => {
  const year = new Date().getFullYear(); // current year

  return (
    <footer className="footer">
      <p>&copy; {year} Coins Exchange</p>
    </footer>
  );
};

export default Footer;
