// src/utils/helpers.js
export const isIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const getQueryParameter = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const shortenWalletAddress = (address) => {
  if (!address || address.length < 12) return address;
  return `${address.slice(0, 10)}...${address.slice(-10)}`;
};

export const isWebView = () => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
  
  // Android WebView detection
  const isAndroidWebView = (/Android/i.test(userAgent) && !/Chrome/i.test(userAgent) && /wv/i.test(userAgent));
  
  // iOS WebView detection
  const isIOSWebView = (/iPhone|iPod|iPad/i.test(userAgent) && /AppleWebKit/i.test(userAgent) && !/Safari/i.test(userAgent));
  
  return isAndroidWebView || isIOSWebView;
};