// src/components/ErrorPopup.js
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetError } from "../features/auth/authSlice";
import "./ErrorPopup.scss";

const ErrorPopup = () => {
  const authError = useSelector((state) => state.auth.error);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authError) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
        setTimeout(() => dispatch(resetError()), 700); // Wait for fade-out animation to finish
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [authError, dispatch]);

  if (!authError) {
    return null;
  }

  return (
    <div className={`error-popup ${!visible ? "fade-out" : ""}`}>
      <p>{authError}</p>
    </div>
  );
};

export default ErrorPopup;
