// src/components/SideMenu.js
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu } from "../features/menu/menuSlice";
import { selectBridge, deselectBridge } from "../features/bridge/bridgeSlice";
import { setView } from "../features/view/viewSlice.js"; // Import setView action

const SideMenu = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.menu.isOpen);
  const isLoggedIn = useSelector((state) => state.auth.user);

  const handleButtonClick = (bridge) => {
    dispatch(selectBridge(bridge));
    dispatch(toggleMenu());
  };

  const handleAboutUsClick = () => {
    dispatch(deselectBridge());
    dispatch(setView("about"));
    dispatch(toggleMenu());
  };

  const handleHomeClick = () => {
    dispatch(deselectBridge());
    dispatch(setView("home"));
    dispatch(toggleMenu());
  };

  return (
    <div className={`side-menu ${isOpen ? "open" : ""}`}>
      <button onClick={handleHomeClick}>Home</button>
      <button onClick={handleAboutUsClick}>About Us</button>
      {isLoggedIn && (
        <>
          <button onClick={() => handleButtonClick("Playnance Bridge Front")}>
            Buy USDP
          </button>
          <button
            onClick={() => handleButtonClick("Playnance Swap Bridge Front")}
          >
            Sell USDP
          </button>
        </>
      )}
    </div>
  );
};

export default SideMenu;
