// src/Login.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  setEmail,
  setWallet,
  setWalletValidity,
} from "./features/auth/authSlice";
import { deselectBridge } from "./features/bridge/bridgeSlice"; // Import deselectBridge
import topLogo from "./Images/top_logo.png"; // Import the top logo
import footerLogo from "./Images/logo_com.png"; // Import the footer logo
import infoIcon from "./Images/info_icon.png"; // Import the info icon
import backIcon from "./Images/back_icon.png"; // Import the back icon
import { isIframe, getQueryParameter, shortenWalletAddress, isWebView } from "./utils/helpers"; // Import helper functions

const Login = () => {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.auth.email);
  const wallet = useSelector((state) => state.auth.wallet);
  const isWalletValid = useSelector((state) => state.auth.isWalletValid);
  const authStatus = useSelector((state) => state.auth.status);
  const selectedBridge = useSelector((state) => state.bridge.selectedBridge);

  useEffect(() => {
    if (isIframe() || isWebView()) {
      const walletAddress = getQueryParameter("walletAddress");
      if (walletAddress) {
        dispatch(setWallet(walletAddress));
        dispatch(setWalletValidity(true));
      }
    }
  }, [dispatch]);

  const handleEmailChange = (e) => {
    dispatch(setEmail(e.target.value));
  };

  const handleWalletChange = (e) => {
    dispatch(setWallet(e.target.value));
  };

  const handleLogin = () => {
    if (email && (isWalletValid || isIframe() || isWebView())) {
      dispatch(login({ email, wallet }));
    }
  };

  const handleBack = () => {
    dispatch(deselectBridge());
  };

  const bridgeText =
    selectedBridge === "Playnance Bridge Front" ? "Buy USDP" : "Sell USDP";
  const walletPlaceholder =
    selectedBridge === "Playnance Bridge Front"
      ? "Recipient Address"
      : "Sender Address";

  const buttonStyle = {
    backgroundColor:
      selectedBridge === "Playnance Bridge Front" ? "#019eff" : "#fe9725",
    color: "#fff",
  };

  return (
    <div className="login">
      <div className="login-container">
        <header className="login-header">
          <button
            className="back-button"
            onClick={handleBack}
            style={{ backgroundImage: `url(${backIcon})` }}
          />
          <h1>{bridgeText}</h1>
          <div
            className="top-logo"
            style={{ backgroundImage: `url(${topLogo})` }}
          />
        </header>
        <h2>Enter your details to log in and continue</h2>
        <div className="input-group">
          <label htmlFor="email">Enter Your Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Email"
          />
        </div>
        {(!isIframe() || !isWebView()) && (
          <div className="input-group">
            <label htmlFor="wallet">{walletPlaceholder}</label>
            <div className="input-wrapper">
              <input
                type="text"
                id="wallet"
                value={shortenWalletAddress(wallet)}
                onChange={handleWalletChange}
                placeholder={walletPlaceholder}
              />
              {/* <img src={infoIcon} alt="info" className="info-icon" /> */}
            </div>
          </div>
        )}
        <button
          onClick={handleLogin}
          disabled={authStatus === "loading" || (!isWalletValid && (!isIframe() || !isWebView()))}
          style={buttonStyle}
        >
          {authStatus === "loading" ? "Logging in..." : "Login"}
        </button>
        <div className="error-container">
          {!isWalletValid && wallet && (!isIframe() || !isWebView()) && (
            <p className="error">Invalid wallet address</p>
          )}
        </div>
        <footer className="login-footer">
          <div
            className="footer-logo"
            style={{ backgroundImage: `url(${footerLogo})` }}
          />
        </footer>
      </div>
    </div>
  );
};

export default Login;
