// src/components/Helmet.js
import React from "react";
import { Helmet } from "react-helmet";

const HelmetComponent = () => (
  <Helmet>
    <title>Coins Exchange</title>
    <meta
      name="description"
      content="A professional crypto exchange application"
    />
    <meta
      name="keywords"
      content="crypto, exchange, blockchain, trading, coins"
    />
    <meta name="author" content="Coins Exchange Inc." />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="icon" href="/favicon.ico" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/site.webmanifest" />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap"
    />
  </Helmet>
);

export default HelmetComponent;
