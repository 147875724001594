// src/components/Hamburger.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleMenu } from '../features/menu/menuSlice';
import SideMenu from './SideMenu'; // Import the SideMenu component

const Hamburger = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.menu.isOpen);

  return (
    <>
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={() => dispatch(toggleMenu())}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <SideMenu /> {/* Include the SideMenu */}
    </>
  );
};

export default Hamburger;
