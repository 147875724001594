// src/utils/api.js
import axios from "axios";

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

const post = async (url, data) => {
  // Check if the environment is development
  const isDevelopment = process.env.NODE_ENV === "development";
  console.log("isDevelopment:", isDevelopment);
  if (isDevelopment) {
    // Mocked response for development environment
    console.log("Mocked response for login endpoint in development");
    return {
      message: "Success",
      data: true,
    };
  }
  // End of development environment check

  try {
    const response = await api.post(url, data);
    return response.data;
  } catch (error) {
    console.error("API POST Error:", error);
    throw error;
  }
};

export { post };
