// src/App.js
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./App.scss";
import HomePage from "./HomePage";
import Login from "./Login";
import ErrorPopup from "./components/ErrorPopup";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HelmetComponent from "./components/Helmet";
import { checkUserLoggedIn } from "./features/auth/authSlice";
import { bridges, deselectBridge } from "./features/bridge/bridgeSlice";
import AboutUs from "./components/AboutUs";
import { setView } from "./features/view/viewSlice";

const App = () => {
  const dispatch = useDispatch();
  const { user, status, wallet } = useSelector((state) => state.auth);
  const selectedBridge = useSelector((state) => state.bridge.selectedBridge);
  const theme = useSelector((state) => state.theme);
  const currentView = useSelector((state) => state.view.currentView); // Get current view from Redux
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const iframeRef = useRef(null);

  const handleCloseBridge = () => {
    dispatch(deselectBridge());
    dispatch(setView("home"));
  };

  useEffect(() => {
    dispatch(checkUserLoggedIn());
  }, [dispatch]);

  useEffect(() => {
    setIsLoggedIn(!!user); // Convert user object to boolean
  }, [user]);

  const renderContent = () => {
    switch (currentView) {
      case "about":
        return <AboutUs />;
      case "home":
        return <HomePage />;
      default:
        return <HomePage />;
    }
  };

  const buildIframeSrc = () => {
    const bridgeUrl = bridges[selectedBridge];
    const params = new URLSearchParams({
      wa: wallet,
      appDomain: window.location.host,
      userMagicAddress: user?.publicAddress,
    });
    return `${bridgeUrl}/?${params.toString()}`;
  };

  if (status === "loading") {
    return (
      <div className={`App ${theme}`}>
        <HelmetComponent />
        <Header />
        <div className="loading">
          <div className="spinner"></div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className={`App ${theme}`}>
      <HelmetComponent />
      <Header />
      <ErrorPopup />
      {!selectedBridge && renderContent()}
      {selectedBridge && !isLoggedIn && <Login />}
      {selectedBridge && isLoggedIn && user && (
        <div className="bridge-iframe">
          <button className="close-button" onClick={handleCloseBridge} />
          <iframe
            ref={iframeRef}
            src={buildIframeSrc()} // Generate the iframe src with parameters
            allow="clipboard-read; clipboard-write"
            title={selectedBridge}
            width="100%"
            height="100%"
            frameBorder="0"
          />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default App;
