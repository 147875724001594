// src/components/AboutUs.js
import React from "react";

const AboutUs = () => {
  return (
    <div className="about-us">
      <section className="about-us__intro">
        <h1 className="about-us__title">About Us</h1>
        <p className="about-us__subtitle">
          We are a leading company in the industry, committed to delivering the
          best services and products to our customers.
        </p>
      </section>
      <section className="about-us__values">
        <h2 className="about-us__section-title">Our Values</h2>
        <div className="about-us__values-list">
          <div className="about-us__value">
            <h3 className="about-us__value-title">Innovation</h3>
            <p className="about-us__value-description">
              We are constantly innovating to meet the evolving needs of our
              customers and the industry.
            </p>
          </div>
          <div className="about-us__value">
            <h3 className="about-us__value-title">Integrity</h3>
            <p className="about-us__value-description">
              Integrity is at the core of our business. We believe in doing the
              right thing, always.
            </p>
          </div>
          <div className="about-us__value">
            <h3 className="about-us__value-title">Customer Focus</h3>
            <p className="about-us__value-description">
              Our customers are our top priority. We are dedicated to providing
              exceptional service and support.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
