// src/HomePage.js
import React from "react";
import { useSelector } from "react-redux";
// import UserInfo from "./components/UserInfo";
import BridgeSelection from "./components/BridgeSelection";

const HomePage = () => {
  const selectedBridge = useSelector((state) => state.bridge.selectedBridge);
  // const user = useSelector((state) => state.auth.user);

  if (selectedBridge) {
    return null; // Do not render HomePage if a bridge is selected
  }

  return (
    <div className="homepage">
      {/* {user && <UserInfo />} */}
      <BridgeSelection />
    </div>
  );
};

export default HomePage;
