// src/store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import bridgeReducer from "./features/bridge/bridgeSlice";
import authReducer from "./features/auth/authSlice";
import themeReducer from "./features/theme/themeSlice";
import menuSlice from "./features/menu/menuSlice";
import viewReducer from "./features/view/viewSlice";

const reducers = combineReducers({
  theme: themeReducer,
  menu: menuSlice,
  bridge: bridgeReducer,
  auth: authReducer,
  view: viewReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // Persist only the auth reducer
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
export default store;
