// src/features/auth/authSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Magic } from "magic-sdk";
import { OAuthExtension } from "@magic-ext/oauth";
import Web3 from "web3";
import ENDPOINTS from "../../utils/endpoints";
import { post } from "../../utils/api";

const magicAuth = {
  rpcUrl:
    "https://lb.drpc.org/ogrpc?network=playnance&dkey=AmsA2632Wk99pZ1Ym7OZe_v82IluOW0R76echkHL9tz4",
  chainId: 1829,
  apiKey: "pk_live_F002BA4BF3BE2974",
};

const customNodeOptions = {
  rpcUrl: magicAuth.rpcUrl,
  chainId: magicAuth.chainId,
};

const magic = new Magic(magicAuth.apiKey, {
  network: customNodeOptions,
  extensions: [new OAuthExtension()],
});

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, wallet }, { rejectWithValue }) => {
    try {
      await magic.auth.loginWithEmailOTP({ email });
      const userInfo = await magic.user.getInfo();
      const idToken = await magic.user.getIdToken();
      console.log(`ID Token for email ${email}: ${idToken}`);

      // Verify user with backend
      const response = await post(ENDPOINTS.LOGIN, {
        didToken: idToken,
        destinationWallet: wallet,
      });

      if (response.message === "Success" && response.data === true) {
        return { ...userInfo, idToken, wallet };
      } else {
        await magic.user.logout();
        return rejectWithValue("User verification failed");
      }
    } catch (error) {
      console.error("Login error:", error);
      await magic.user.logout();
      return rejectWithValue(error.message);
    }
  }
);

export const checkUserLoggedIn = createAsyncThunk(
  "auth/checkUserLoggedIn",
  async () => {
    const isLoggedIn = await magic.user.isLoggedIn();
    if (isLoggedIn) {
      const userInfo = await magic.user.getInfo();
      const idToken = await magic.user.getIdToken();
      console.log(`ID Token for logged-in user: ${idToken}`);
      return { ...userInfo, idToken };
    }
    return null;
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await magic.user.logout();
  localStorage.clear(); // Clear local storage
  return null;
});

export const connectMetaMask = createAsyncThunk(
  "auth/connectMetaMask",
  async () => {
    console.log("Attempting to connect with MetaMask...");
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          console.log("Connected with MetaMask:", accounts[0]);
          return { wallet: accounts[0] };
        } else {
          console.error("No accounts found");
          throw new Error("No accounts found");
        }
      } catch (error) {
        console.error("MetaMask connection error:", error);
        throw error;
      }
    } else {
      console.error("MetaMask is not installed");
      throw new Error("MetaMask is not installed");
    }
  }
);

const validateWalletAddress = (address) => {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
};

const initialState = {
  user: null,
  status: "idle",
  error: null,
  email: "",
  wallet: "",
  isWalletValid: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },
    setWallet(state, action) {
      state.wallet = action.payload;
      state.isWalletValid = validateWalletAddress(action.payload);
    },
    setWalletValidity(state, action) {
      state.isWalletValid = action.payload;
    },
    resetError(state) {
      state.error = null;
    },
    resetState: () => initialState, // Add a reset state reducer
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(checkUserLoggedIn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkUserLoggedIn.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(checkUserLoggedIn.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(logout.fulfilled, (state) => {
        // state.user = null;
        return initialState; // Reset the state to the initial state
      })
      .addCase(connectMetaMask.pending, (state) => {
        state.status = "loading";
      })
      .addCase(connectMetaMask.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.wallet = action.payload.wallet;
        state.isWalletValid = true;
      })
      .addCase(connectMetaMask.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const {
  setEmail,
  setWallet,
  setWalletValidity,
  resetError,
  resetState,
} = authSlice.actions;
export default authSlice.reducer;
